import React, { useEffect, useState, useRef } from "react";
import ReactDom from "react-dom";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";


import "./../assets/custom-modal.css";

import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { useMediaQuery } from 'react-responsive';
import MediaQuery from 'react-responsive'
//import CardCategorySelection from "./CardCategorySelection";

import Modal from 'react-bootstrap/Modal';
import { Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const modalAppuntamento = document.getElementById("modal-appuntamento");

const ModalAppuntamento = props => {
  const refModal = useRef();
  const [modalOpacity, setModalOpacity] = useState(0);
  const transitionTime = 300;
  const styles = { transition: `all linear ${transitionTime}ms` };

  const [mainCategories, setMainCategories] = useState([]);

  const [validated, setValidated] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

  const closeModalAppuntamento = () => {
    setModalOpacity(0);
    setTimeout(() => props.closeModal(), transitionTime);
  };

  const modalAction = () => {
    createEventsApiRequest();
    setModalOpacity(0);
    setTimeout(() => props.modalAction(), transitionTime);
  };


  const cambiaViewTabCategoria = () => {
    if (categoriaScelta) {
      setCategoriaScelta(false);
    } else
    if (!categoriaScelta) {
      setCategoriaScelta(true);
    }
  };


  // ----

  const {categories} = useSelector((state) => state.getCategories);
  const [categoryChoosen, setCategoryChoosen] = useState("Choose category");
  
  const {products} = useSelector((state) => state.getProducts);
  const [productChoosen, setProductChoosen] = useState("Choose product");

  const {resources} = useSelector((state) => state.getResources);
  const [resourceChoosen, setResourceChoosen] = useState("Choose resource");

  
  const [product, setProduct] = useState({});

  const createEventsApiRequest = async (eventId, title, name, surname, telephone, email, address) => {
    const { data } = await axios.put(`/api/events/${eventId}`, { title, name, surname, telephone, email, address });
    return data;
}

const isMobile = useMediaQuery({ query: '(min-width: 576px)' });
const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
const isPC = useMediaQuery({ query: '(min-width: 992px)' });

const [activeTab, setActiveTab] = useState('appuntamento');

const [categoriaScelta, setCategoriaScelta] = useState(false);
const [prodottoScelto, setProdottoScelto] = useState(false);


function handleTabChange() {
  if (activeTab === 'appuntamento') {
      setActiveTab(1);
  }
  if (activeTab === 'categoria') {
      setActiveTab(2);
  }
}

function toNextTab(e) {
  e.preventDefault();
  handleTabChange();
}

function toServizioTab(e) {
  e.preventDefault();
  toNextTab();
}

function handleSelectTab(key) {
  if (key === 1)
    this.setState({ heading: "Appuntamento" })
  if (key === 2)
    this.setState({ heading: "Categorie" })
  else
    this.setState({ heading: "Servizi" })
  }
  

  const handleSelectTabServizio = (eventKey) => {
    setActiveTab(3);
}


const CardCategorySelection = ({ category, idx }) => { 
  return (
    <Card body outline color="success" className="mx-2 my-2">
      <Card.Img crossOrigin="anonymous" variant="top" src={category.image ?? null} />
      <Card.Body>
        <Card.Title>{category.name}</Card.Title>
        <Card.Text>
          {category.description}
        </Card.Text>
          <Button variant="success" onClick={handleSelectTabServizio}>
            Seleziona
          </Button>
      </Card.Body>
    </Card>   
  );
};

  useEffect(() => {
    setActiveTab(1);
    setModalOpacity(1);
    const handleClick = e => {
      if (refModal.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      closeModalAppuntamento();
    };

    // add when rendered
    document.addEventListener("mousedown", handleClick);
    // return clean up function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };


  }, []);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;
    const formInputs = {
      title: form.title.value,
      name: form.name.value,
      surname: form.surname.value,
      telephone: form.telephone.value,
      email: form.email.value,
      address: form.address.value,
    };
    createEventsApiRequest(formInputs);
    setValidated(true);
    //window.location.assign('/admin/resources')
  };
   
  const changeCategory = (e, categories, setCategoryChoosen) => {
    const highLevelCategory = e.target.value.split("/")[0];
    const highLevelCategoryAllData = categories.find(
      (cat) => cat.name === highLevelCategory
    );
    setCategoryChoosen(e.target.value);
  };

  const changeProduct = (e, products, setProductChoosen) => {
    const highLevelProduct = e.target.value.split("/")[0];
    const highLevelProductAllData = products.find(
      (pro) => pro.name === highLevelProduct
    );
    setProductChoosen(e.target.value);
  };

  const changeResource = (e, resources, setResourceChoosen) => {
    const highLevelResource = e.target.value.split("/")[0];
    const highLevelResourceAllData = resources.find(
      (res) => res.nome === highLevelResource
    );
    setResourceChoosen(e.target.value);
  };

  const ViewTabServizioCategoriaNonScelta = () => {
    return (
      <Card body outline color="success" className="mx-2 my-2">
        <Card.Body>
          <Card.Title>scegli la Categoria</Card.Title>
          <Card.Text>
            123456
          </Card.Text>
        </Card.Body>
      </Card>   
    );
  };
  
  const ViewTabServizioCategoriaScelta = () => {
    return (
      <Row xs={5} md={4} className="g-5 mt-4">
        {categories.map((category, idx) => (
          <CardCategorySelection key={idx} category={category} idx={idx} />
        ))}
      </Row>
    );
  };
  
  return ReactDom.createPortal(
    <div >
      <div
        className={props.isOpenTabAppuntamento ? "modal fade-n show" : "modal fade-out"}
        style={
          props.isOpenTabAppuntamento
            ? { ...styles, opacity: modalOpacity, display: "block" }
            : { ...styles, opacity: modalOpacity, display: "none" }
        }
        id="exampleModal"
        tabIndex="-1"
      >

        <Modal.Dialog size="lg">

            <Modal.Header closeButton onClick={closeModalAppuntamento}>
                <h3>Appuntamento</h3>&nbsp;&nbsp; 10/10/2024 riga={props.riga} {props.colonna} vc{categoriaScelta}
                
            </Modal.Header>

            <Modal.Body>

                <Tabs id="uncontrolled-tab-example"                
                  className="mb-3"
                  defaultActiveKey={1}
                  activeKey={activeTab}        
                  onSelect={(key) => setActiveTab(key)}         
                >
                      
                    <Tab eventKey={1} title="Appuntamento" activeKey={2}>
        
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-default"
                                style={{
                                    width: "100px",
                                }}>
                                Ora
                                </InputGroup.Text>
                                <Form.Control name="title" defaultValue={props.ora} required type="text" />
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-default"
                                style={{
                                    width: "100px",
                                }}>
                                Cliente
                                </InputGroup.Text>
                                <Form.Control name="title" defaultValue={props.value} required type="text" />
                            </InputGroup>

                            <Form.Group className="mb-3" controlId="formBasicCategory">
                                <Form.Label>Categoria</Form.Label>
                                <Form.Select
                                    required
                                    name="category"
                                    aria-label="Default select example"
                                    onChange={(e) => changeCategory(e, categories,setCategoryChoosen)}
                                >
                                    <option value="Choose category">Scegli categoria</option>
                                    {categories.map((category, idx) => {
                                    return (
                                        <option key={idx} value={category.name}>
                                        {category.name}
                                        </option>
                                    );
                                    })}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicProduct">
                                <Form.Label>Servizio</Form.Label>
                                <Form.Select
                                    required
                                    name="product"
                                    aria-label="Default select example"
                                    onChange={(e) =>  changeProduct(e, products,setProductChoosen)}
                                >
                                    <option value="Choose product">Scegli prodotto</option>
                                    {products.map((product, idx) => {
                                    return (
                                        <option key={idx} value={product.name}>
                                        {product.name}
                                        </option>
                                    );
                                    })}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicResource">
                                <Form.Label>Risorsa</Form.Label>
                                <Form.Select
                                    required
                                    name="resource"
                                    aria-label="Default select example"
                                    onChange={(e) => changeResource(e, resources,setResourceChoosen)}
                                >
                                    <option value="Choose resource">Scegli risorsa</option>
                                    {resources.map((resource, idx) => {
                                    return (
                                        <option key={idx} value={resource.name}>
                                        {resource.name}
                                        </option>
                                    );
                                    })}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicResource">
                                <Form.Label>Site</Form.Label>
                                <Form.Select
                                    required
                                    name="resource"
                                    aria-label="Default select example"
                                    onChange={(e) => changeResource(e, resources,setResourceChoosen)}
                                >
                                    <option value="Choose resource">Scegli risorsa</option>
                                    {resources.map((resource, idx) => {
                                    return (
                                        <option key={idx} value={resource.name}>
                                        {resource.name}
                                        </option>
                                    );
                                    })}
                                </Form.Select>
                            </Form.Group>
        
                        </Form>
        
                    </Tab>

                    <Tab eventKey={2} title="Categoria" activeKey={activeTab} >

                        <Row xs={3} md={3} className="g mt">
                            {categories.map((category, idx) => (
                            <CardCategorySelection key={idx} category={category} idx={idx} />
                            ))}
                        </Row>

                    </Tab>
    
                    <Tab eventKey={3} title="Servizio">

                      {categoriaScelta && (
                        <Row xs={1} md={1} className="g-0 mt-0">
                            {categories.map((category, idx) => (
                            <CardCategorySelection key={idx} category={category} idx={idx} />
                            ))}
                        </Row>
                      )}

                      {!categoriaScelta && (

                          <ViewTabServizioCategoriaNonScelta></ViewTabServizioCategoriaNonScelta>

                      )}                   

                    </Tab>
    
                </Tabs>

            </Modal.Body>

            <Modal.Footer>
               <button
                type="button"
                className="btn btn-danger"
                onClick={closeModalAppuntamento}
              >
                Cancella
              </button>

              <button
                type="button"
                className="btn btn-success"
                onClick={modalAction}
              >
                Salva
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={closeModalAppuntamento}
              >
                Chiudi
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSelectTabServizio}
              >
                TabServ
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={cambiaViewTabCategoria}
              >
                CambiViewCat
              </button>

            </Modal.Footer>

        </Modal.Dialog>

      </div>
      <div className={`background`} />
    </div>,
    modalAppuntamento
  );
};

export default ModalAppuntamento;
