import * as actionTypes from "../constants/resourceConstants";

export const getResourcesReducer = (state = { resources: [] }, action) => {
    switch (action.type) {
        case actionTypes.GET_RESOURCES_REQUEST:
           return {
               ...state,
               resources: action.payload,
           }
        case actionTypes.SAVE_ATTR:
            return {
                ...state,
                resources: action.payload,
            }
        case actionTypes.INSERT_RESOURCE:
            return {
                ...state,
                resources: action.payload,
            }
        case actionTypes.DELETE_RESOURCE:
            return {
               ...state,
               resources: action.payload,
            }
        default:
            return state;
    }
}