import * as actionTypes from "../constants/productConstants";

export const getProductsReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCTS_REQUEST:
           return {
               ...state,
               products: action.payload,
           }
        case actionTypes.SAVE_ATTR:
            return {
                ...state,
                products: action.payload,
            }
        case actionTypes.INSERT_PRODUCT:
            return {
                ...state,
                products: action.payload,
            }
        case actionTypes.DELETE_PRODUCT:
            return {
               ...state,
               products: action.payload,
            }
        default:
            return state;
    }
}