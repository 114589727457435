import { Row, Col, Table, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";

import { useState, useEffect } from "react";

import { logout } from "../../../redux/actions/userActions";
import { useDispatch } from "react-redux";

const AdminResourcesPageComponent = ({ fetchResources, deleteResource }) => {
  const [resources, setResources] = useState([]);
  const [resourceDeleted, setResourceDeleted] = useState(false); 
  const dispatch = useDispatch();

  const deleteHandler = async (resourceId) => {
    if (window.confirm("Sei sicuro?")) {
        const data = await deleteResource(resourceId)
        if (data.message === "resource removed") {
            setResourceDeleted(!resourceDeleted);
        }
        //navigate("/admin/resources");
        window.location.assign('/admin/resources')
    }
  };

  useEffect(() => {
    const abctrl = new AbortController();
    fetchResources(abctrl)
      .then((res) => setResources(res))
      .catch((er) =>
      dispatch(logout())
        // setResources([
        //   {name: er.response.data.message ? er.response.data.message : er.response.data}
        // ])
      );
    return () => abctrl.abort();
  }, [resourceDeleted]);

  return (
    <Row className="m-5">
      <Col md={2}>
        <AdminLinksComponent />
      </Col>
      <Col md={10}>
        <h1>
          Risorse {" "} 
          <LinkContainer to="/admin/create-new-resource">
            <Button variant="primary" size="lg">
              Nuova Risorsa
            </Button>
          </LinkContainer>
        </h1>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Titolo</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Modifica/Cancella</th>
            </tr>
          </thead>
          <tbody>
            {resources.map((resource, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{resource.title}</td>
                <td>{resource.name}</td>
                <td>{resource.surname}</td>
                <td>
                  <LinkContainer to={`/admin/edit-resource/${resource._id}`}>
                    <Button className="btn-sm">
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  </LinkContainer>
                  {" / "}
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(resource._id)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default AdminResourcesPageComponent;
