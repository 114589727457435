import AdminResourcesPageComponent from "./components/AdminResourcesPageComponent";
import axios from "axios";

const fetchResources = async (abctrl) => {
    const {data} = await axios.get("/api/resources", {
        signal: abctrl.signal,
    });
    return data
}

const deleteResource = async (userId) => {
    const { data } = await axios.delete(`/api/resources/${userId}`);
    return data
}

const AdminResourcesPage = () => {
  return <AdminResourcesPageComponent fetchResources={fetchResources} deleteResource={deleteResource} />;
};

export default AdminResourcesPage;