import EditResourcePageComponent from "./components/EditResourcePageComponent";
import axios from "axios";

const fetchResource = async (resourceId) => {
    const { data } = await axios.get(`/api/resources/${resourceId}`);
    return data;
}

const updateResourceApiRequest = async (resourceId, title, name, surname, telephone, email, address) => {
    const { data } = await axios.put(`/api/resources/${resourceId}`, { title, name, surname, telephone, email, address });
    return data;
}

const AdminEditResourcePage = () => {
  
  return <EditResourcePageComponent updateResourceApiRequest={updateResourceApiRequest} fetchResource={fetchResource} />;
};


export default AdminEditResourcePage;
