import CreateResourcePageComponent from "./components/CreateResourcePageComponent";
import axios from "axios";
import { useSelector } from "react-redux";
import { newResource,deleteResource, saveAttributeToCatDoc } from "../../redux/actions/resourceActions";
import { useDispatch } from "react-redux";

const createResourceApiRequest = async (formInputs) => {
  const { data } = await axios.post(`/api/resources`, { ...formInputs });
  return data;
};

const AdminCreateResourcePage = () => {
  const { resources } = useSelector((state) => state.getCategories);
  const dispatch = useDispatch();

  return (
    <CreateResourcePageComponent
      createResourceApiRequest={createResourceApiRequest}
      resources={resources}
      reduxDispatch={dispatch}
      newResource={newResource}
      deleteResource={deleteResource}
      saveAttributeToCatDoc={saveAttributeToCatDoc}
    />
  );
};

export default AdminCreateResourcePage;
