import React, { useEffect, useState, useRef } from "react";
import ReactDom from "react-dom";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";


import "./../assets/custom-modal.css";

import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { useMediaQuery } from 'react-responsive';
import MediaQuery from 'react-responsive'

import Modal from 'react-bootstrap/Modal';
import { Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { Link, withRouter } from 'react-router-dom';

const modalServizio = document.getElementById("modal-servizio");

const ModalServizio = props => {
  const refModal = useRef();
  const [modalOpacity, setModalOpacity] = useState(0);
  const transitionTime = 300;
  const styles = { transition: `all linear ${transitionTime}ms` };

  const [validated, setValidated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const closeModal = () => {
    setModalOpacity(0);
    setTimeout(() => props.closeModal(), transitionTime);
  };

  const modalAction = () => {
    createEventsApiRequest();
    setModalOpacity(0);
    setTimeout(() => props.modalAction(), transitionTime);
  };

  // ----

  const {categories} = useSelector((state) => state.getCategories);
  const [categoryChoosen, setCategoryChoosen] = useState("Choose category");
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { categoryName } = "Architettura: cantieri";
  const { pageNumParam } = 1;
  const { searchQuery } = "";
  const [filters, setFilters] = useState({}); // collect all filters
  const [sortOption, setSortOption] = useState("");
  const [paginationLinksNumber, setPaginationLinksNumber] = useState(null);
  const [pageNum, setPageNum] = useState(null);


//  const {products} = useSelector((state) => state.getProducts);
  const [productChoosen, setProductChoosen] = useState("Choose product");

  const [products, setProducts] = useState([]);

  const {resources} = useSelector((state) => state.getResources);
  const [resourceChoosen, setResourceChoosen] = useState("Choose resource");
  
  const [product, setProduct] = useState({});

  let filtersUrl = "";

  const proceedFilters = (filters) => {
      filtersUrl = "";
      Object.keys(filters).map((key, index) => {
         if (key === "price") filtersUrl += `&price=${filters[key]}`; 
         else if (key === "rating") {
             let rat = "";
             Object.keys(filters[key]).map((key2, index2) => {
                 if (filters[key][key2]) rat += `${key2},`;
                 return "";
             })
             filtersUrl += "&rating=" + rat;
         } else if (key === "category") {
             let cat = "";
             Object.keys(filters[key]).map((key3, index3) => {
                 if (filters[key][key3]) cat += `${key3},`;
                 return "";
             })
             filtersUrl += "&category=" + cat;
         } else if (key === "attrs") {
            if (filters[key].length > 0) {
               let val = filters[key].reduce((acc, item) => {
                   let key = item.key;
                   let val = item.values.join("-");
                   return acc + key + "-" + val + ",";
               }, "") 
               filtersUrl += "&attrs=" + val;
            } 
         }
         return "";
      })
      return filtersUrl;
  }
  
  const getProducts = async (categoryName = "", pageNumParam = null, searchQuery = "", filters = {}, sortOption = "") => {
      //   filtersUrl = "&price=60&rating=1,2,3&category=a,b,c,d&attrs=color-red-blue,size-1TB-2TB";
      filtersUrl = proceedFilters(filters);
      const search = searchQuery ? `search/${searchQuery}/` : "";
      const category = categoryName ? `category/${categoryName}/` : "";
      const url = `/api/products/${category}${search}?pageNum=${pageNumParam}${filtersUrl}&sort=${sortOption}`;
      const { data } = await axios.get(url);
      return data
  }  



  const createEventsApiRequest = async (eventId, title, name, surname, telephone, email, address) => {
    const { data } = await axios.put(`/api/events/${eventId}`, { title, name, surname, telephone, email, address });
    return data;
}

const isMobile = useMediaQuery({ query: '(min-width: 576px)' });
const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
const isPC = useMediaQuery({ query: '(min-width: 992px)' });

const [activeTab, setActiveTab] = useState('servizio');

function handleTabChange() {
  if (activeTab === 'servizio') {
      setActiveTab(1);
  }
  if (activeTab === 'categoria') {
      setActiveTab(2);
  }
}

function toNextTab(e) {
  e.preventDefault();
  handleTabChange();
}

function toServizioTab(e) {
  e.preventDefault();
  toNextTab();
}
 
  const reg = () => {
    this.props.doneInModalServizio(this.state.doneInModalServizio);
  }

  const handleCategorySelected = (categoryName) => {
    setCategoryChoosen(categoryName);
    ViewTabServizioCategoryChoosen();
    //window.location.assign(`/product-list/category/${categoryChoosen}`)
    setActiveTab(3);     
  }


const CardCategorySelection = ({ category, idx }) => { 
  return (
    <Card body outline color="success" className="mx-2 my-2">
      <Card.Img crossOrigin="anonymous" variant="top" src={category.image ?? null} />
      <Card.Body>
        <Card.Title>{category.name}</Card.Title>
        <Card.Text>
          {category.description}
        </Card.Text>
        {/* 
          <LinkContainer to={`/product-list/category/${category.name}`}>
            <Button variant="success" onClick={() => handleCategorySelected(category.name)}>
              Seleziona
            </Button>
          </LinkContainer>
        */}
          <Button variant="success" onClick={() => handleCategorySelected(category.name)}>
              Seleziona
            </Button>
          </Card.Body>
    </Card>   
  );
};

const ViewTabServizioNoCategoryChoosen = () => {
  return (
    <Card body outline color="success" className="mx-2 my-2">
      <Card.Body>
        <Card.Title>scegli la Categoria</Card.Title>
        <Card.Text>
          123456
        </Card.Text>
      </Card.Body>
    </Card>   
  );
};

const ViewTabServizioCategoryChoosen = () => {
  return (
    <Row xs={5} md={4} className="g-5 mt-4">
      {categories.map((category, idx) => (
        <CardCategorySelection key={idx} category={category} idx={idx} />
      ))}
    </Row>
  );
};

const ViewTabCategorie = () => {
  return (
    <Row xs={5} md={4} className="g-5 mt-4">
      {categories.map((category, idx) => (
        <CardCategorySelection key={idx} category={category} idx={idx} />
      ))}
    </Row>
  );
};


  useEffect(() => {
    setActiveTab(1);
    setModalOpacity(1);
    const handleClick = e => {
      if (refModal.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      closeModal();
    };

    // add when rendered
    document.addEventListener("mousedown", handleClick);
    // return clean up function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  

  useEffect(() => {
    
    getProducts(categoryName, pageNumParam, searchQuery, filters, sortOption)
      .then((products) => {
        setProducts(products.products);
        setPaginationLinksNumber(products.paginationLinksNumber);
        setPageNum(products.pageNum);
        setLoading(false);
      })
      .catch((er) => {
        console.log(er);
        setError(true);
      });
  }, [categoryName, pageNumParam, searchQuery, filters, sortOption]
);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;
    const formInputs = {
      title: form.title.value,
      name: form.name.value,
      surname: form.surname.value,
      telephone: form.telephone.value,
      email: form.email.value,
      address: form.address.value,
    };
    createEventsApiRequest(formInputs);
    setValidated(true);
    //window.location.assign('/admin/resources')
  };
   
  const changeCategory = (e, categories, setCategoryChoosen) => {
    const highLevelCategory = e.target.value.split("/")[0];
    const highLevelCategoryAllData = categories.find(
      (cat) => cat.name === highLevelCategory
    );
    setCategoryChoosen(e.target.value);
  };

  const changeProduct = (e, products, setProductChoosen) => {
    const highLevelProduct = e.target.value.split("/")[0];
    const highLevelProductAllData = products.find(
      (pro) => pro.name === highLevelProduct
    );
    setProductChoosen(e.target.value);
  };

  const changeResource = (e, resources, setResourceChoosen) => {
    const highLevelResource = e.target.value.split("/")[0];
    const highLevelResourceAllData = resources.find(
      (res) => res.nome === highLevelResource
    );
    setResourceChoosen(e.target.value);
  };

  return ReactDom.createPortal(
    <div >
      <div
        className={props.isOpen ? "modal fade-n show" : "modal fade-out"}
        style={
          props.isOpen
            ? { ...styles, opacity: modalOpacity, display: "block" }
            : { ...styles, opacity: modalOpacity, display: "none" }
        }
        id="exampleModal"
        tabIndex="-1"
      >

        <Modal.Dialog size="lg">

            <Modal.Header closeButton onClick={closeModal}>
                <h3>scegli il servizio</h3>&nbsp;&nbsp; app 10/10/2024 riga={props.riga} {props.colonna} {categoryChoosen}
                
            </Modal.Header>

            <Modal.Body>

              <ViewTabCategorie></ViewTabCategorie>
                    
            </Modal.Body>

            <Modal.Footer>

              <button
                type="button"
                className="btn btn-primary"
                onClick={closeModal}
              >
                Chiudi
              </button>

            </Modal.Footer>

        </Modal.Dialog>

      </div>
      <div className={`background`} />
    </div>,
    modalServizio
  );
};

export default ModalServizio;
