import * as actionTypes from "../constants/resourceConstants";

import axios from "axios";

export const getResources = () => async (dispatch) => {
    const { data } = await axios.get("/api/resources");
    dispatch({
        type: actionTypes.GET_RESOURCES_REQUEST,
        payload: data,
    })
}

export const saveAttributeToCatDoc = (key, val, resourceChoosen) => async (dispatch, getState) => {
   const { data } = await axios.post("/api/resources/attr", { key, val, resourceChoosen }); 
   if (data.resourceUpdated) {
       dispatch({
           type: actionTypes.SAVE_ATTR,
           payload: [...data.resourceUpdated],
       })
   }
}

export const newResource = (resource) => async (dispatch, getState) => {
    const res = getState().getResources.resources;
    const { data } = await axios.post("/api/resources", { resource });
    if (data.resourceCreated) {
        dispatch({
            type: actionTypes.INSERT_RESOURCE,
            payload: [...res, data.resourceCreated],
        })
    }
}

export const deleteResource = (resource) => async (dispatch, getState) => {
    const res = getState().getResources.resources;
    const resources = res.filter((item) => item.name !== resource);
    const { data } = await axios.delete("/api/resources/" + encodeURIComponent(resource));
    if (data.resourceDeleted) {
        dispatch({
           type: actionTypes.DELETE_RESOURCE, 
           payload: [...resources],
        })
    }
}